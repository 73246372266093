// Subset of social headers which are most commonly overwritten.

import React from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";

class PageTitle extends React.Component {
  render() {
    const {
      data: {
        siteName,
        title: siteTitle,
      },
      title: pageTitle,
    } = this.props;
    var title
    if (!!pageTitle) {
      title = `${pageTitle} - ${siteName}`
    } else {
      title = siteTitle
    }

    return (
      <Helmet>
        <title>{title}</title>
        <meta itemprop="name" content={title} />
        <meta name="twitter:title" content={title} />
        <meta property="og:title" content={title} />
      </Helmet>
    );
  }
}

PageTitle.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        siteName: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      })
    })
  }),
  title: PropTypes.string,
};

export default props => (
  <StaticQuery
    query={graphql`
      query PageTitleQuery {
        site {
          siteMetadata {
            siteName
            title
          }
        }
      }
    `}
    render={({site: {siteMetadata}}) => <PageTitle data={siteMetadata} {...props} />}
  />
);
