import React from 'react'
import PropTypes from 'prop-types'
import rehypeReact from "rehype-react"

export const HTMLContent = ({ content, toc}) => {

  const TOC = () => (
    <>
      <div className="toc" dangerouslySetInnerHTML={{ __html: toc }} />
      <div />
    </>
  );

  // HACK - use image title to set size.  For now, don't support passing title through
  const imgSizer = ({title: classes = "medium", ...others}) => (
      <img {...others} className={classes}/> // eslint-disable-line jsx-a11y/alt-text
  );

  const adjacent = ({children, ...others}) => (
    <div {...others} className="adjacent">{children}</div>
  );

  const captioned = ({children, caption}) => (
    <>
      <figure>
        {children}
        <figcaption>{caption}</figcaption>
      </figure>
    </>
  );

  const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: { 
      "adjacent": adjacent,
      "captioned": captioned,
      "img": imgSizer,
      "toc": TOC,
    },
  }).Compiler

  return (<div className="blogContent">{renderAst(content)}</div>)
}

const Content = ({ content, className }) => (
  <div className={"blogContent" + className}>{content}</div>
)

Content.propTypes = {
  content: PropTypes.any,  // could be node or ast?
  className: PropTypes.string,
  toc: PropTypes.string,
}

HTMLContent.propTypes = Content.propTypes

export default Content
