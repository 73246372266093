import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import Tags from './Tags'

class BlogRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <>
        {posts &&
          posts.map(({ node: post }) => (
              <article className="tile is-child notification">
                <p>
                  <div className="is-size-4 is-spaced">
                    <Link className="title has-text-primary"
                      
                      to={post.fields.slug}
                    >
                      {post.frontmatter.title}
                    </Link>
                    &nbsp;&bull; {post.frontmatter.date}
                  </div>
                </p>
                <p>
                  <div className="blogContent" dangerouslySetInnerHTML={{ __html: post.excerpt}}></div>
                  <div className="level tags-excerpt">
                    <div className="level-left">
                      <Tags tags={post.frontmatter.tags} />
                    </div>
                    <div className="level-right">
                      <Link className="button" to={post.fields.slug}>
                        Keep Reading →
                      </Link>
                    </div>
                  </div>
                </p>
              </article>
          ))}
      </>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { 
            frontmatter: { 
              templateKey: { eq: "blog-post" }
              draft: { eq: false }
            } 
          }
        ) {
          edges {
            node {
              excerpt(format: HTML)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                tags
                date(formatString: "MMMM DD, YYYY")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)


// NB: to get TOC working for blog roll would need to support `excerpt(format: AST)`.
//     would require changes here: 
//     https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-transformer-remark/src/extend-node-type.js#L417

