import React from 'react'
import Link from 'gatsby-link'
import logo from "../img/logo.png"
import { SocialIcon } from 'react-social-icons'

class Navbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false
    }
  }

  toggleBurger() {
    this.setState({active: !this.state.active})
  }

  render() {
    return (
      <div className="columns">
        <div className="column is-10 is-offset-1">
          <nav className="navbar is-transparent">
            <div className="navbar-brand">
              { !this.props.isHome && (
                <Link className="navbar-item" to="/">
                  <img src={logo} alt="Just Kai logo"/>
                </Link>
              )}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a role="button" className={ "navbar-burger " + (this.state.active ? "is-active" : "") }
                aria-label="menu" aria-expanded="false"
                onClick={()=>this.toggleBurger()} 
              >
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </a>
            </div>
            <div className={"navbar-menu is-right " + (this.state.active ? "is-active" : "")}>
              <div className="navbar-start">
                <Link className="navbar-item" to="/blog">
                  Blog
                </Link>
                <Link className="navbar-item" to="/guides">
                  Guides
                </Link>
                <Link className="navbar-item" to="/about">
                  About Us
                </Link>
                <Link className="navbar-item" to="/contact">
                  Contact
                </Link>
              </div>
              <div className="navbar-end">
                <div className="navbar-item">
                  <SocialIcon url="https://www.facebook.com/justkainz" style={{ height: 30, width: 30 }}/>
                </div>
                <div className="navbar-item">
                  <SocialIcon url="https://twitter.com/justkainz" style={{ height: 30, width: 30 }}/>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    )
  }
}
export default Navbar
