import React from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";
import PageTitle from "./PageTitle";

class SocialHeaders extends React.Component {
  render() {
    const {
      data: {
        siteUrl,
        siteName,
        fbAdminID,
        siteTwitter,
        description: siteDescription,
        siteImg
      },
      title,
      description: pageDescription,
      image: pageImg,
      tags,
      published,
      modified,
      path = ""
    } = this.props;
    const url = siteUrl + path;
    const imageFull = siteUrl + (pageImg || siteImg);
    const description = pageDescription || siteDescription;

    return (
      <>
        <Helmet>
          <title>{title}</title>
          {description && <meta name="description" content={description} />}

          {/* <!-- Schema.org markup for Google+ --> */}
          <meta itemprop="name" content={title} />
          {description && <meta itemprop="description" content={description} />}
          <meta itemprop="image" content={imageFull} />

          {/* <!-- Twitter Card data --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content={siteTwitter} />
          <meta name="twitter:title" content={title} />
          {description && (
            <meta name="twitter:description" content={description} />
          )}
          {/* <meta name="twitter:creator" content="@TODO" /> */}
          {/* <!-- Twitter summary card with large image must be at least 280x150px --> */}
          <meta name="twitter:image:src" content={imageFull} />

          {/* <!-- Open Graph data --> */}
          <meta property="og:title" content={title} />
          <meta property="og:url" content={url} />
          <meta property="og:image" content={imageFull} />
          <meta property="og:type" content="article" />
          {description && (
            <meta property="og:description" content={description} />
          )}
          <meta property="og:site_name" content={siteName} />
          {published && (
            <meta property="article:published_time" content={published} />
          )}
          {modified && (
            <meta property="article:modified_time" content={modified} />
          )}
          {tags &&
            tags.map((t, i) => (
              <meta key={`tag-${i}`} property="article:tag" content={t} />
            ))}
          <meta property="fb:admins" content={fbAdminID} />
        </Helmet>
        <PageTitle title={title} />
      </>
    );
  }
}

SocialHeaders.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        siteUrl: PropTypes.string.isRequired,
        fbAdminID: PropTypes.string.isRequired,
        siteName: PropTypes.string.isRequired,
        siteTwitter: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        siteImg: PropTypes.string.isRequired
      })
    })
  }),
  title: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  published: PropTypes.string, // iso format date
  modified: PropTypes.string, // iso format date
  path: PropTypes.string
};

export default props => (
  <StaticQuery
    query={graphql`
      query SocialHeadersQuery {
        site {
          siteMetadata {
            siteUrl
            siteName
            fbAdminID
            siteTwitter
            description
            siteImg
          }
        }
      }
    `}
    render={({ site: { siteMetadata } }) => (
      <SocialHeaders data={siteMetadata} {...props} />
    )}
  />
);
