import React from 'react'

import Navbar from '../components/Navbar'
import './all.sass'
import SocialHeaders from './SocialHeaders';

const TemplateWrapper = ({ children }) => (
  <div>
    <SocialHeaders />
    <Navbar />
    <div>{children}</div>
  </div>
)

export default TemplateWrapper
