import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Tags from '../components/Tags'
import SocialHeaders from '../components/SocialHeaders'

export const BlogPostTemplate = ({
  content,
  contentComponent,
  tags,
  title,
  description,
  image,
  published,
  modified,
  toc,
  slug,
}) => {
  const PostContent = contentComponent || Content
  
    return (
    <section className="section">
      <SocialHeaders
        title={title}
        description={description}
        image={image}
        published={published}
        modified={modified}
        tags={tags}
        path={slug}
      />
      <div className="container content">
        <div className="columns">
          <div className="column is-9 is-offset-1">
            <h1 className="title is-size-3 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <PostContent content={content} toc={toc}/>
            <div className="tags-full-blog">
              <Tags tags={tags} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.any.isRequired,  // sometimes a Node, sometimes an AST
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  published: PropTypes.string,
  modified: PropTypes.string,
  toc: PropTypes.string,
  slug: PropTypes.string,
}

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <BlogPostTemplate
        content={post.htmlAst}
        contentComponent={HTMLContent}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        image={post.frontmatter.image}
        published={post.frontmatter.date}
        modified={post.frontmatter.updated}
        toc={post.tableOfContents}
        slug={post.fields.slug}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      htmlAst
      tableOfContents
      fields { slug }
      frontmatter {
        date
        title
        tags
        description
        image
        updated
      }
    }
  }
`
