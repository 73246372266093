import React from 'react'
import {Link} from 'gatsby'
import {FaRss} from 'react-icons/fa'
import Layout from '../../components/Layout'
import BlogRoll from '../../components/BlogRoll'
import PageTitle from '../../components/PageTitle';

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <PageTitle title="Blog" />
        <div className="container">
          <div className="columns">
            <div className="column is-offset-1 is-9">
              <div className="section content">
                <div className="blogroll-header">
                  <h1 className="title">Latest Posts</h1>
                  <div className="title"><a href="/rss.xml" className="rss"><FaRss/></a></div>
                </div>
                <div class="tile is-parent is-vertical">
                  <BlogRoll />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}