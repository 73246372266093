import React from "react";
import Navbar from '../components/Navbar';
import '../components/all.sass';
import PageTitle from "../components/PageTitle";

export default class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tab: 'home'
    }
  }

  changeTabs(newTab) {
    this.setState({tab: newTab})
  }

  render() {

    return (
      <div>
        <PageTitle title="Success" />
        <Navbar />
        <div class="section">
          <div class="container">
            <div class="title">Thank You</div>
            Your message has been sent.
          </div>
        </div>
      </div>
    );
  }
}
