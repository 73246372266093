import React from 'react'

import Layout from '../../components/Layout'
import PageTitle from '../../components/PageTitle';

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <PageTitle title="Guides" />
        <div className="container">
          <div className="columns">
            <div className="column is-offset-1 is-9">
              <div className="section content">
                <h1 className="title">Shopping Guides</h1>
                <p>
                  Which brands are slave-free? Learn about the issues involved in food supply chains and how you can support human rights while shopping.
                </p>
                <ul>
                  <li><a href="/guides/cocoa">Cocoa</a></li>
                  <li><a href="/guides/sugar">Sugar</a></li>
                  <li><a href="/guides/tinned-tuna-sardines-and-mackerel">Tinned Tuna, Sardines &amp; Mackerel</a></li>
                </ul>
                <h3 className="title">Research Notes for Fish Products</h3>
                <p>
                  For cocoa and sugar, products with trusted certifications and those grown in low-risk
                  countries can be relied on to be slave-free. However for fish, as there are no
                  low-risk countries and currently no reliable human rights certifications, we've done
                  our own research to identify which products are slave-free. These notes provide a record
                  of that research – they are published here so you can understand and critique our recommendations.
                </p>
                <p>
                  Over time we hope to produce a <b>shopping guide</b> to go along with each of these research notes.
                  In the meantime, check out the PDF summary (within each research note) for brands to look out
                  for when shopping.
                </p>
                <p>
                  We also hope to expand our research to cover further fish categories.
                </p>
                <ul>
                  <li>
                    <a href="/research/fish-salmon">Salmon</a>
                  </li>
                  <li>
                    <a href="/research/fish-tinned">
                      Tinned Tuna, Sardines &amp; Mackerel
                    </a>
                  </li>
                  <li>
                    <a href="/research/fish-petfood">Fish in Pet Food</a>
                  </li>
                  <li>
                    <a href="/research/fish-supplements">
                      Fish Oil and Omega 3 Supplements
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}