import React from "react";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import Navbar from "../components/Navbar";
import "../components/all.sass";
import header from "../img/header.png";
import SocialHeaders from "../components/SocialHeaders";

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "home",
      notify: false // change here to enable seasonal notification
    };
  }

  changeTabs(newTab) {
    this.setState({ tab: newTab });
    // console.log("TAB: " + newTab)
    if (window.ga) {
      window.ga("send", "event", {
        eventCategory: "ChangeTab",
        eventAction: "click",
        eventLabel: newTab,
        transport: "beacon"
      });
    }
    return true;
  }

  closeNotification() {
    this.setState({ notify: false });
    return true;
  }

  render() {
    return (
      <div>
        <Navbar isHome />
        <SocialHeaders />
        <span className="container">
          <div className="columns is-centered">
            <div className="column is-3">
              <img alt="header text: Just Kai" src={header} />
            </div>
          </div>
          <div className="columns">
            <div className="column is-8 is-offset-2">
              {/* Seasonal Notification - `notify` default value controls if shows */}
              {this.state.notify && (
                <div class="notification is-warning is-size-4">
                  <button
                    id="notice-delete"
                    class="delete"
                    onClick={() => this.closeNotification()}
                  />
                  <div class="title has-text-centered">
                    2020 Easter Egg List&nbsp;
                    <a href="/blog/2020-03-14-just-kai-easter-list-2020/">
                      Now Available
                    </a>
                    !
                  </div>
                  {/* We have{" "}
                  <OutboundLink
                    target="_blank"
                    href="https://justkai.org.nz/blog/2019-11-18-christmas-2019-festive-meal"
                  >
                    Christmas meal ideas
                  </OutboundLink>{" "}
                  (
                  <OutboundLink
                    target="_blank"
                    href="https://drive.google.com/open?id=1OJYi-nUQYnEScgyDgdCLdaEzO53xZ-dO"
                  >
                    summary pdf
                  </OutboundLink>
                  ) and suggestions for{" "}
                  <OutboundLink
                    target="_blank"
                    href="https://justkai.org.nz/blog/2019-11-18-christmas-2019-gift-ideas/"
                  >
                    chocolatey gifts
                  </OutboundLink>{" "}
                  (
                  <OutboundLink
                    target="_blank"
                    href="https://drive.google.com/open?id=1jwl8Z0O2vuPklJQMYqqF1oD9YvKTjqOi"
                  >
                    summary pdf
                  </OutboundLink>
                  ) */}
                </div>
              )}
              <div className="card column is-10 is-offset-1">
                <div className="card-content">
                  <div className="title is-size-4 has-text-weight-normal">
                    “the dominant things we buy that are likely to have been
                    produced by slave labour are computers and mobile phones,
                    clothing, <b>fish</b>, <b>cocoa</b> and <b>sugar</b>.”
                  </div>
                  <div className="subtitle is-size-6 has-text-right">
                    <OutboundLink
                      target="_blank"
                      href="https://www.globalslaveryindex.org/2018/findings/highlights/"
                    >
                      Global Slavery Index 2018
                    </OutboundLink>{" "}
                    (
                    <OutboundLink
                      target="_blank"
                      href="https://www.globalslaveryindex.org/resources/downloads/"
                    >
                      pdf p. iv
                    </OutboundLink>
                    )
                  </div>
                </div>
              </div>

              <div className="section content">
                <p>
                  People far away are enslaved to produce our food. We can stop
                  that by only buying from companies that have rooted slavery
                  out of their supply chains.
                </p>
                <a name="tab-head" />{" "}
                {/* eslint-disable-line jsx-a11y/anchor-is-valid  jsx-a11y/anchor-has-content */}
                {/* Sadly can't see how to apply conditional is-large, so have duplicated tabs for now */}
                <div className="tabs is-boxed is-hidden-tablet">
                  <ul>
                    <li
                      className={this.state.tab === "home" ? "is-active" : ""}
                    >
                      <a onClick={() => this.changeTabs("home")}>
                        We Recommend
                      </a>
                    </li>{" "}
                    {/* eslint-disable-line jsx-a11y/anchor-is-valid */}
                    <li
                      className={this.state.tab === "fish" ? "is-active" : ""}
                    >
                      <a onClick={() => this.changeTabs("fish")}>Fish</a>
                    </li>{" "}
                    {/* eslint-disable-line jsx-a11y/anchor-is-valid */}
                    <li
                      className={this.state.tab === "cocoa" ? "is-active" : ""}
                    >
                      <a onClick={() => this.changeTabs("cocoa")}>Cocoa</a>
                    </li>{" "}
                    {/* eslint-disable-line jsx-a11y/anchor-is-valid */}
                    <li
                      className={this.state.tab === "sugar" ? "is-active" : ""}
                    >
                      <a onClick={() => this.changeTabs("sugar")}>Sugar</a>
                    </li>{" "}
                    {/* eslint-disable-line jsx-a11y/anchor-is-valid */}
                  </ul>
                </div>
                <div className="tabs is-boxed is-hidden-mobile is-large">
                  <ul>
                    <li
                      className={this.state.tab === "home" ? "is-active" : ""}
                    >
                      <a onClick={() => this.changeTabs("home")}>
                        We Recommend
                      </a>
                    </li>{" "}
                    {/* eslint-disable-line jsx-a11y/anchor-is-valid */}
                    <li
                      className={this.state.tab === "fish" ? "is-active" : ""}
                    >
                      <a onClick={() => this.changeTabs("fish")}>Fish</a>
                    </li>{" "}
                    {/* eslint-disable-line jsx-a11y/anchor-is-valid */}
                    <li
                      className={this.state.tab === "cocoa" ? "is-active" : ""}
                    >
                      <a onClick={() => this.changeTabs("cocoa")}>Cocoa</a>
                    </li>{" "}
                    {/* eslint-disable-line jsx-a11y/anchor-is-valid */}
                    <li
                      className={this.state.tab === "sugar" ? "is-active" : ""}
                    >
                      <a onClick={() => this.changeTabs("sugar")}>Sugar</a>
                    </li>{" "}
                    {/* eslint-disable-line jsx-a11y/anchor-is-valid */}
                  </ul>
                </div>
                <div id="tabContent">
                  {this.state.tab === "home" && (
                    <React.Fragment>
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <b>Fish</b>
                            </td>
                            <td>
                              <p>
                                We are very confident the entire Sealord range
                                is slave free. We have been very impressed by
                                their commitment to human rights. Brunswick
                                sardines are also slave free.
                              </p>

                              <div className="tile is-ancestor is-3">
                                <div className="tile is-parent">
                                  <div className="tile is-child">
                                    <Img
                                      className="hovers"
                                      fluid={
                                        this.props.data.imgSealord
                                          .childImageSharp.fluid
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="tile is-parent">
                                  <div className="tile is-child">
                                    <Img
                                      className="hovers"
                                      fluid={
                                        this.props.data.imgBrunswick
                                          .childImageSharp.fluid
                                      }
                                    />
                                  </div>
                                </div>
                              </div>

                              <p>
                                A lot of pet food contains fish. These and other
                                products are discussed on the 'Fish' tab.
                              </p>
                              <p className="is-hidden-tablet">
                                <a
                                  onClick={() => this.changeTabs("fish")}
                                  href="#tab-head"
                                >
                                  Learn more...
                                </a>
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Cocoa</b>
                            </td>
                            <td>
                              <p>
                                Slave free cocoa products can easily be
                                identified by the following logos:
                              </p>

                              <div className="tile is-ancestor is-6">
                                <div className="tile is-parent is-2">
                                  <div className="tile is-child">
                                    <Img
                                      className="hovers"
                                      fluid={
                                        this.props.data.imgWFTO.childImageSharp
                                          .fluid
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="tile is-parent">
                                  <div className="tile is-child">
                                    <Img
                                      className="hovers"
                                      fluid={
                                        this.props.data.imgFLOMulti
                                          .childImageSharp.fluid
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="tile is-parent is-3">
                                  <div className="tile is-child">
                                    <Img
                                      className="hovers"
                                      fluid={
                                        this.props.data.imgUTZ.childImageSharp
                                          .fluid
                                      }
                                    />
                                  </div>
                                </div>
                              </div>

                              <p>Samoan grown cocoa is also slave free.</p>
                              <p className="is-hidden-tablet">
                                <a
                                  onClick={() => this.changeTabs("cocoa")}
                                  href="#tab-head"
                                >
                                  Learn more...
                                </a>
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Sugar</b>
                            </td>
                            <td>
                              <p>
                                Slave free sugar can be identified through the
                                following logos:
                              </p>

                              <div className="tile is-ancestor is-4">
                                <div className="tile is-parent is-6">
                                  <div className="tile is-child">
                                    <Img
                                      className="hovers"
                                      fluid={
                                        this.props.data.imgBonSucro
                                          .childImageSharp.fluid
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="tile is-parent">
                                  <div className="tile is-child">
                                    <Img
                                      className="hovers"
                                      fluid={
                                        this.props.data.imgWFTO.childImageSharp
                                          .fluid
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="tile is-parent">
                                  <div className="tile is-child">
                                    <Img
                                      className="hovers"
                                      fluid={
                                        this.props.data.imgFAIRTRADE_Mark
                                          .childImageSharp.fluid
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <p>
                                Countdown own brand sugar has Bonsucro
                                certification; Trade Aid sugar has WFTO
                                certification; Chantal Organics sugar has
                                Fairtrade certification.
                              </p>
                              <p className="is-hidden-tablet">
                                <a
                                  onClick={() => this.changeTabs("sugar")}
                                  href="#tab-head"
                                >
                                  Learn more...
                                </a>
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <p>
                        {" "}
                        Download a{" "}
                        <OutboundLink
                          target="_blank"
                          href="https://drive.google.com/open?id=1_BzwqIx5FSpZjyUozsmc9_VxqQMsuDNI"
                        >
                          summary pdf
                        </OutboundLink>
                        .
                      </p>
                    </React.Fragment>
                  )}
                  {this.state.tab === "fish" && (
                    <React.Fragment>
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <b>Watch Out For:</b>
                            </td>
                            <td>
                              Forced and child labour are common in the fishing
                              industry: on boats, in fish farms and in fish
                              processing facilities. These practises are most
                              commonly associated with Thailand but occur
                              throughout East and South-East Asia; slavery has
                              also been reported on fishing boats in New Zealand
                              waters.
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>How To Know:</b>
                            </td>
                            <td>
                              There are no certifications to tell you these
                              things haven’t occurred: you have to ask companies
                              what happens throughout their supply chain
                              instead. For farmed fish and prawns this includes
                              asking how feed species were caught.
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Good Options:</b>
                            </td>
                            <td>
                              <p>
                                Your pets might be eating more fish than you!
                                Fish is common in pet food even if it’s not in
                                the product name - check the ingredients.
                                Download{" "}
                                <OutboundLink
                                  target="_blank"
                                  href="https://drive.google.com/open?id=1hhvO7naM9vIQriOua28toxlMNzsMEgll"
                                >
                                  summary pdf
                                </OutboundLink>{" "}
                                of good brands; read{" "}
                                <a href="/guides/fish-petfood">
                                  detailed research report
                                </a>
                                .
                              </p>
                              <p>
                                Download an{" "}
                                <OutboundLink
                                  target="_blank"
                                  href="https://drive.google.com/open?id=1udUolH107raznWKbNo1yjirbXNKstnc8"
                                >
                                  overview pdf
                                </OutboundLink>{" "}
                                of good brands of fish for people.
                              </p>
                              <p>
                                Tinned tuna, sardines and mackerel: download{" "}
                                <OutboundLink
                                  target="_blank"
                                  href="https://drive.google.com/open?id=12PGK1wG2MLQXtx1az2H0dRNwqpYJvEwp"
                                >
                                  summary pdf
                                </OutboundLink>
                                ; read{" "}
                                <a href="/guides/fish-tinned">
                                  detailed research report
                                </a>
                                .
                              </p>
                              <p>
                                Salmon (all forms - tinned and fresh): download{" "}
                                <OutboundLink
                                  target="_blank"
                                  href="https://drive.google.com/open?id=1J3O5g0eVL2ar5PJ7q21kDe5xj_UO8n9o"
                                >
                                  summary pdf
                                </OutboundLink>
                                ; read{" "}
                                <a href="/guides/fish-salmon">
                                  detailed research report
                                </a>
                                .
                              </p>
                              <p>
                                Fish oil and omega 3 supplements: download{" "}
                                <OutboundLink
                                  target="_blank"
                                  href="https://drive.google.com/open?id=1aOyCK-Q7uACJUirGe3B_NSfbxiEyirJw"
                                >
                                  summary pdf
                                </OutboundLink>
                                ; read{" "}
                                <a href="/guides/fish-supplements">
                                  detailed research report
                                </a>
                                .
                              </p>
                              <p>
                                Shellfish: from as-yet unpublished research, all
                                New Zealand-sourced shellfish (both wildcaught
                                and farmed) is slave free.
                              </p>
                              <p>
                                From partly-published research (see tuna and
                                salmon research reports above) we are confident
                                the entire Sealord range is slave free. Sealord
                                produce plain and flavoured tuna and salmon in
                                both tins and pockets; they also produce
                                mussels, tuna-based ready-meals and frozen fish
                                products.
                              </p>
                              <p>
                                We continue to research further sectors of the
                                NZ fish market.
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </React.Fragment>
                  )}
                  {this.state.tab === "cocoa" && (
                    <React.Fragment>
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <b>Watch Out For:</b>
                            </td>
                            <td>
                              Forced labour is common on cocoa farms, especially
                              in the Ivory Coast and Ghana where such workers
                              are often children trafficked from neighbouring
                              countries.
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>How To Know:</b>
                            </td>
                            <td>
                              <p>
                                Always look for certifications to be sure your
                                cocoa is slave free.
                              </p>
                              <p>
                                Not all certifications are reliable. We trust{" "}
                                <a href="/blog/2019-05-12-utz-certification">
                                  UTZ
                                </a>
                                ,{" "}
                                <OutboundLink
                                  target="_blank"
                                  href="http://fairtrade.org.nz"
                                >
                                  Fairtrade
                                </OutboundLink>{" "}
                                and{" "}
                                <OutboundLink
                                  target="_blank"
                                  href="https://wfto.com"
                                >
                                  WFTO
                                </OutboundLink>
                                . We have also found no evidence of slavery ever
                                being practised on Samoan cocoa farms: we trust
                                Samoan grown cocoa to be slave free.
                              </p>
                              <p>
                                If you want to buy an uncertified product or one
                                with a different certification,{" "}
                                <a href="/blog/2019-05-12-how-to-buy-chocolate-without-supporting-abuse-of-cocoa-growers">
                                  read this first
                                </a>{" "}
                                so you know how to check it’s slave free.
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Good Options:</b>
                            </td>
                            <td>
                              <p>
                                There are a wide range of products available
                                with our trusted certifications: as well as
                                chocolate you'll find ice cream, baking
                                ingredients and even toiletries. Download{" "}
                                <OutboundLink
                                  target="_blank"
                                  href="https://drive.google.com/open?id=1prhYppKbBv4dpso_5B-4reXZ9wp-7R1B"
                                >
                                  a list
                                </OutboundLink>{" "}
                                or <a href="/guides/cocoa">read online</a>.
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </React.Fragment>
                  )}
                  {this.state.tab === "sugar" && (
                    <React.Fragment>
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <b>Watch Out For:</b>
                            </td>
                            <td>
                              Forced labour occurs on sugar farms in many
                              countries; it is especially common in Brazil. This
                              is fuelled in part by some wealthy sugar-growing
                              countries (e.g. USA) subsidising their local
                              industries, which pushes prices down
                              internationally. To avoid supporting slavery, both
                              subsidised and slave-grown sugar should be
                              avoided.
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>How To Know:</b>
                            </td>
                            <td>
                              We trust Bonsucro, WFTO and Fairtrade
                              certifications. Read more about why{" "}
                              <a href="/guides/sugar">here.</a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Good Options:</b>
                            </td>
                            <td>
                              <p>
                                Countdown own brand sugar has Bonsucro
                                certification: they do white, brown, caster and
                                icing sugar.
                              </p>
                              <p>
                                Trade Aid sugar has WFTO certification: they do
                                muscovado, golden granulated and organic cane
                                sugar.
                              </p>
                              <p>
                                Chantal Organics sugar has Fairtrade
                                certification: they do golden sugar.
                              </p>
                              <p>
                                Sugar-heavy products with Fairtrade
                                certification include: Karma Cola soft drinks,
                                The Goodness dessert and coffee syrups, Nice
                                Blocks ice blocks and Ben and Jerry's ice cream.
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </span>
      </div>
    );
  }
}

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 600) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    imgBrunswick: file(relativePath: { eq: "logos/brunswick_polish.png" }) {
      ...fluidImage
    }
    imgSealord: file(relativePath: { eq: "logos/sealord.jpg" }) {
      ...fluidImage
    }
    imgWFTO: file(relativePath: { eq: "logos/wfto.png" }) {
      ...fluidImage
    }
    imgFAIRTRADE_Mark: file(relativePath: { eq: "logos/FAIRTRADE_Mark.png" }) {
      ...fluidImage
    }
    imgFLOMulti: file(relativePath: { eq: "logos/flo_cocoa_logos.jpg" }) {
      ...fluidImage
    }
    imgUTZ: file(relativePath: { eq: "logos/utz_cocoa_plan.jpg" }) {
      ...fluidImage
    }
    imgBonSucro: file(relativePath: { eq: "logos/bonsucro-376x174.jpeg" }) {
      ...fluidImage
    }
  }
`;
