import { graphql } from 'gatsby'
import Img from "gatsby-image";
import Link from 'gatsby-link'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import React from 'react'
import PageTitle from '../../components/PageTitle';
import Navbar from '../../components/Navbar';

export default class AboutPage extends React.Component {
  render() {
    return (
      <div>
        <PageTitle title="About Us" />
        <Navbar />
        <div className="container">
          <div className="columns">
            <div className="column is-8 is-offset-1">
              <div className="section content">
                <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                  About Us
                </h2>
                  <p>Just Kai is a group of friends who don't want to eat food produced with slave labour.  
                  We research supply chains to find and promote slave free choices.  We focus on the three foods where slavery is most common: fish, cocoa and sugar.</p>

                  <div className="column is-8 is-offset-2" >
                    <figure>
                      <Img fluid={this.props.data.imgTeam.childImageSharp.fluid} />
                      <figcaption>
                          Sarah, Anna, Heather & Martin
                      </figcaption>
                    </figure>
                  </div>
                  <p>Most of the research has been carried out by Heather.  
                  You can hear her talking about some of her Biblical motivation for this work <OutboundLink target="_blank" href="https://www.youtube.com/watch?v=7p_gwwU-JDk">here</OutboundLink>.  
                  Heather blogs at <OutboundLink target="_blank" href="https://blog.puriri.nz/">Little Notes from Heather and Martin</OutboundLink>.</p>
                  <p>We're keen to meet like-minded people: if this is something you're passionate about, please <Link to="/contact">get in touch</Link>!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export const pageQuery = graphql`
  query {
    imgTeam: file(relativePath: { eq: "profile.JPG" }) {
      ...fluidImage
    }
  }
`;
