import { kebabCase } from 'lodash'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'


const Tags = ({tags}) => {
  if (!tags || !tags.length) {
    return null
  }
  return (
    <div className="is-text-white buttons">
      {tags.map(tag => (
        <Link key={tag+'-tag'} 
              to={`/tags/${kebabCase(tag)}/`} 
              className="button is-link is-rounded is-small">
          {tag}
        </Link>
      ))}
    </div>
  )
}

Tags.propTypes = {
  children: PropTypes.string,
  key: PropTypes.string
}

export default Tags

