import React from "react";
import PageTitle from '../components/PageTitle';
import Navbar from '../components/Navbar';
import '../components/all.sass';

export default class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tab: 'home'
    }
  }

  changeTabs(newTab) {
    this.setState({tab: newTab})
  }

  render() {

    return (
      <div>
        <PageTitle title="Contact Us" />
        <Navbar />
        <div class="container">
          <div class="columns">
            <div className="column is-half-desktop is-offset-1">
              <div className="section content">
                <div className="title">Contact Us</div>

                <form name="contact" method="POST" action="/success/" className="subtitle"
                    data-netlify="true" data-netlify-honeypot="bob">
                <input type="hidden" name="form-name" value="contact" />
                <p class="honeypot">
                  <label>Your Favourite Flower: <input type="text" name="bob" /></label>
                </p>
                <div class="field">
                  <label class="label">Name</label>
                  <div class="control">
                    <input class="input" type="text" name="name" />
                  </div>
                </div>

                <div class="field">
                  <label class="label">Email</label>
                  <div class="control">
                    <input class="input" type="email" name="email"/>
                  </div>
                </div>

                <div class="field">
                  <label class="label">Message</label>
                  <div class="control">
                    <textarea class="textarea" name="msg"></textarea>
                  </div>
                </div>

                <div class="field">
                  <div class="control">
                    <button type="submit" class="button is-link">Submit</button>
                  </div>
                </div>
              </form>
            </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
